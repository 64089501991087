import React, { useCallback, useState } from 'react'
import LogoLight from '../images/svg/cb_log_2024_orange_white.svg'
import LogoDark from '../images/svg/cb_log_2024_orange_white.svg' 

import { Link, useStaticQuery, graphql, navigate } from 'gatsby'

const HamburgerButton = ({ onClick }) => {
  return (
    <div
      class="p-3 space-y-2 rounded shadow cursor-pointer bg-dark_burnt mx-2 my-2 lg:invisible hover:bg-burnt"
      onClick={onClick}
    >
      <span class="block w-8 h-0.5 bg-white animate-pulse"></span>
      <span class="block w-8 h-0.5 bg-white animate-pulse"></span>
      <span class="block w-8 h-0.5 bg-white animate-pulse"></span>
    </div>
  )
}

const Head = ({ title, theme, authState }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const onHamburgerClick = useCallback(() => {
    setIsMobileMenuOpen((prevState) => !prevState)
  }, [])

  return (
    <div className=" flex lg:flex-row flex-col  lg:w-2/3 w-full  self-center  items-center">
      <div className="lg:w-1/3 w-full flex items-center justify-between">
        <div className="flex flex-row">{title}</div>
        <HamburgerButton onClick={onHamburgerClick} />
      </div>
      <div className="flex-grow">
        <DesktopMenu theme={theme} authState={authState} />
      </div>
      <MobileMenu isOpen={isMobileMenuOpen} authState={authState}/>
    </div>
  )
}

const Branding = ({ theme = 'light' }) => {
  if (theme == 'light') {
    return (
      <LogoLight
        className="cursor-pointer"
        onClick={(e) => navigate('/')}
        width="200px"
        height="50px"
      ></LogoLight>
    )
  }
  return (
    <LogoDark
      className="cursor-pointer"
      onClick={(e) => navigate('/')}
      width="200px"
      height="50px"
    ></LogoDark>
  )
}

const MobileMenu = ({ authState, isOpen }) => {
  const onSupportClick = useCallback(() => {
		window.open('https://help.calendarbridge.com', '_blank')
	}, [])

  return (
    <div
      className={`bg-white absolute lg:hidden top-16 w-11/12 ${
        isOpen ? 'h-30' : 'h-0'
      } transition-all ease-in-out duration-500 overflow-hidden rounded-lg mr-2.5`}
    >
      <div className="flex flex-col p-2 divide-y">
        <Link href="/sync"
          className="hover:text-brand_blue p-2 text-darkblue_highlight"
        >
          Sync
        </Link>
        <Link
         to="/schedule"
          className="hover:text-brand_blue p-2 text-darkblue_highlight"
        >
          Scheduling
        </Link>

        <Link to="/pricing"
          className="cursor-pointer hover:text-brand_blue p-2 text-darkblue_highlight"
        >
          Pricing
        </Link>

        <Link
          className="cursor-pointer hover:text-brand_blue p-2 text-darkblue_highlight"
          to="https://help.calendarbridge.com"
          target="_blank"
        >
          Resources
        </Link>

        {authState === 'signedIn' ? (
          <Link
            to="/dashboard"
            className="cursor-pointer hover:text-brand_blue p-2 text-darkblue_highlight"
          >
            Dashboard
          </Link>
        ) : (
          <>
            <Link
              to="/signup"
              className="cursor-pointer hover:text-brand_blue p-2 text-darkblue_highlight"
            >
              Start Trial
            </Link>

            <Link
              to="/login"
              className="cursor-pointer hover:text-brand_blue p-2 text-darkblue_highlight"
            >
              Login
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

const DesktopMenu = ({ theme, authState }) => {
  return (
    <div className="hidden lg:flex">
      <div className="flex-grow"></div>
      <div className="flex flex-col lg:flex-row items-center">
        <div
          className={`flex flex-row space-x-2 lg:space-x-8  ${
            theme == 'light' ? 'text-darkblue_highlight' : 'text-white'
          } text-bold font-lato items-center mr-8`}
        >
          <Link
            to="/sync"
            className={`cursor-pointer hover:text-brand_blue ${theme == 'light' ? 'text-darkblue_highlight' : 'text-white'}`}
          >
            Sync
          </Link>
          <Link
            to="/schedule"
            className={`cursor-pointer hover:text-brand_blue ${theme == 'light' ? 'text-darkblue_highlight' : 'text-white'}`}
         
          >
            Scheduling
          </Link>

          <Link
            to="/pricing"
            className={`cursor-pointer hover:text-brand_blue ${theme == 'light' ? 'text-darkblue_highlight' : 'text-white'}`}
         
          >
            Pricing
          </Link>

          <div className="cursor-pointer hover:text-brand_blue">
            <a
              className={`${
                theme == 'light' ? 'text-darkblue_highlight' : 'text-white'
              } hover:text-brand_blue`}
              target="_blank"
              href="https://help.calendarbridge.com"
            >
              Resources
            </a>
          </div>
        </div>
      </div>
      <div className="mt-2 lg:mt-0">
        {authState === 'signedIn' ? (
          <Link
            to="/dashboard"
            style={{
              borderRadius: '0.125rem',
            }}
            className="cursor-pointer text-white rounded-sm pl-4 pr-4 pt-2 pb-2 hover:bg-burnt bg-dark_burnt"
          >
            Dashboard
          </Link>
        ) : (
          <div className="flex flex-row gap-x-4">
            <Link
              to="/signup"
              className="cursor-pointer text-roboto text-md  rounded-md pl-4 pr-4 pt-2 pb-2 hover:bg-burnt bg-dark_burnt text-white"
            >
              Start Trial
            </Link>

            <Link
              to="/login"
              className="cursor-pointer bg-white rounded-sm border-2 pl-4 pr-4 pt-2 pb-2 hover:bg-neutral_1 border-brand_dark_blue"
            >
              Login
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

const WebsiteHeader = ({ authState, theme = 'light' }) => {
  return (
    <div className="flex justify-center pl-2 lg:pl-0 text-base">
      <Head
        theme={theme}
        authState={authState}
        title={<Link to="/"><Branding theme={theme}></Branding></Link>}
      ></Head>
    </div>
  )
}

export default WebsiteHeader
