import React from 'react'
import {
  LinkedinOutlined,
  MenuOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'

import { Link, useStaticQuery, graphql, navigate } from 'gatsby'

import LogoDark from '../images/svg/new_CB_logo_dark.svg'

const FooterLink = ({linkText, target}) => {
  return <Link className="text-white hover:to-brand_blue" to={target}>{linkText}</Link>
}

const WebsiteFooter = () => {
  return (
    <div className="bg-black text-white w-full flex flex-col lg:p-12 p-4 text-base">
      <div className=" flex flex-col items-center space-y-4 lg:flex-row md:flex-row justify-between">
        <Link to='/'><LogoDark
          className="lg:ml-4 cursor-pointer"
          width="200px"
          height="50px"
        ></LogoDark></Link>
        <div className="grid grid-cols-3 gap-x-2 gap-y-1">
          <FooterLink linkText='Sync' target='/sync'></FooterLink>
          <FooterLink linkText='Pricing' target='/pricing'></FooterLink>
          <FooterLink linkText='About' target='/about'></FooterLink>
          <FooterLink linkText='Schedule' target='/schedule'></FooterLink>
          <FooterLink linkText='Support Center' target='https://help.calendarbridge.com'></FooterLink>
          <FooterLink linkText='Terms & Privacy' target='/terms-and-privacy'></FooterLink>
          <FooterLink linkText='Sign Up' target='/signup'></FooterLink>
          <FooterLink linkText='FAQs' target='https://help.calendarbridge.com/faqs/home/'></FooterLink>
          <FooterLink linkText='Blog' target='https://help.calendarbridge.com/blog/'></FooterLink>
        </div>
        <div>
          <div className="flex flex-row justify-center items-center">
            <a href="https://www.linkedin.com/company/calendarbridge">
              <LinkedinOutlined
                style={{
                  fontSize: '2em',
                  color: 'white',
                  margin: '8px',
                }}
              />
            </a>
            <a href="https://www.youtube.com/channel/UCNxM_vra0aOIKVX-OqmyDzA">
              <YoutubeOutlined
                style={{
                  fontSize: '2em',
                  color: 'white',
                  margin: '8px',
                }}
              />
            </a>
            <a href="https://twitter.com/CalendarBridge">
              <TwitterOutlined
                style={{
                  fontSize: '2em',
                  color: 'white',
                  margin: '8px',
                }}
              />
            </a>
          </div>
          2023 CalendarBridge{' '}
        </div>
      </div>
    </div>
  )
}

export default WebsiteFooter
